* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-image: url("./img/background.jpg");
  font-family: Arial;
}

.ql-container {
  height: 200px !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-pagination
  .ant-pagination-options {
  display: inline-block;
}
